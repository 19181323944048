import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/sass/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { SENTRY_DNS } from "./config";
console.log( process.env.NODE_ENV ,' process.env.NODE_ENV process.env.NODE_ENV')
Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({levels: ['error']})
  ],
  environment: process.env.NODE_ENV || "development",
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^\//,
    "https://ambitious-sand-0ea68b61e.4.azurestaticapps.net/",
    "https://rocketlister-api-staging.azurewebsites.net/"
  ],
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <HashRouter >
//       <App />
//     </HashRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
