import { PaymentCardListModel } from "../../client/model/PaymentCardListModel";

export interface PaymentStateData {
  cardList: PaymentCardListModel[];
  paymentAddress: any;
  billingAddress: any;
  payingCardId: number;
}

const INITIAL_STATE = {
  cardList: [],
  payingCardId: null,
  paymentAddress: {
    address: null,
    city: null,
    state: null,
    zip_code: null,
  },
  billingAddress: null,
};
export const paymentReducer = (
  state: PaymentStateData = INITIAL_STATE,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_PAYMENT_CARD_LIST": {
      return {
        ...state,
        cardList: Array.from(new Map(payload.cardList.map(card => [card.id, card])).values()),
      };
    }

    case "GET_BILLING_ADDRESS_BY_CARD_ID": {
      return { ...state, billingAddress: payload.billingAddress };
    }
    case "GET_BILLING_ADDRESS": {
      return {
        ...state,
        paymentAddress: {
          address: payload.formattedAddress,
          city: payload.city,
          state: payload.state,
          zip_code: payload.postalCode,
        },
      };
    }
    case "SET_PAYING_CARD_ID": {
      return {
        ...state,
        payingCardId: payload.cardId,
      };
    }

    case "CLEAR_BILLING_ADDRESS": {
      return {
        ...state,
        paymentAddress: {
          address: null,
          city: null,
          state: null,
          zip_code: null,
        },
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
